<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LinkComponent',
  props: {
    href: {
      type: String,
      required: false,
      default: '#',
    },
    variant: {
      type: String as () => 'default' | 'primary' | 'secondary',
      required: false,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentRoute: {
      type: Boolean,
      required: false,
      default: false,
    },
    dotted: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkType: {
      type: String as () => 'menu' | 'menu-title' | 'menu-mobile',
      required: false,
      default: 'medium',
    },
  },
  computed: {
    linkClasses() {
      return [
        'link',
        `link--${this.variant}`,
        { 'link--selected': this.currentRoute, 'link--disabled': this.disabled },
      ];
    },
    linkLabelClasses() {
      return [
        'link__label',
        `link--${this.linkType}`,
      ];
    },
  },
});
</script>

<template>
  <a :href="href" :class="linkClasses">
    <span :class="linkLabelClasses"><slot /></span>
    <span v-if="currentRoute && dotted" class="link__dot"></span>
  </a>
</template>

<style scoped>
.link {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  .link__label {
    display: inline-block;
    font-family: 'Suisse Int\'l', sans-serif;
    text-align: left;
    font-weight: 500;

    &.link--menu {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    &.link--menu-mobile {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }

    &.link--submenu-child-mobile {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
    }

    &.link--menu-title {
      font-size: 2.5rem;
      line-height: 3.5rem;
      font-weight: 300;
      letter-spacing: -2%;
    }
  }

  .link__dot {
    display: block;
    width: 6px;
    height: 6px;
    background-color: currentColor;
    border-radius: 50%;
    margin-top: 4px;
  }

  &.link--default {
    .link__label {
      color: #434356;
    }

    .link__dot {
      background-color: #434356;
    }

    &:hover {
      .link__label {
        color: #8D8DA5;
      }

      .link__dot {
        background-color: #8D8DA5;
      }
    }
  }

  &.link--selected {
    .link__label {
      color: #434356;
      font-weight: 700;
    }

    .link__dot {
      background-color: #434356;
    }
  }

  &.link--primary {
    .link__label {
      color: #007bff;
    }

    .link__dot {
      background-color: #007bff;
    }

    &:hover {
      .link__label {
        color: #6666FF;
      }

      .link__dot {
        background-color: #6666FF;
      }
    }
  }

  &.link--secondary {
    .link__label {
      color: #FFFFFF;
    }

    .link__dot {
      background-color: #FFFFFF;
    }

    &:hover {
      .link__label {
        color: #A9A9BC;
      }

      .link__dot {
        background-color: #A9A9BC;
      }
    }
  }

  @media (min-width: 1025px) {
    &.link--selected {
      margin-top: 0.63rem;
    }
    &.link--primary {
      &:hover {
        .link__label {
          color: #6666FF;
          font-weight: 500;
        }
      }
    }

    &.link--secondary {
      &:hover {
        .link__label {
          color: #A9A9BC;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
